import anime from 'animejs/lib/anime.es.js'

const DURATION = 1500

export default () => ({
  aosId: null,
  listeners: {},

  init () {
    this.aosId = this.$el.dataset.aosId ?? null
    this.listeners[`aos:in:${this.aosId}`] = this.animate.bind(this)

    if (this.aosId) {
      Object.keys(this.listeners).forEach(listener => {
        document.addEventListener(listener, this.listeners[listener].bind(this))
      })
    }
  },

  animate() {
    anime({
      targets: '#growth-graph .graph',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutQuart',
      duration: DURATION,
    })
    anime({
      targets: '#growth-graph path.bar',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutQuart',
      // Duration of graph animation - the delay for the last bar (119 + 200 = 319),
      // so that last bar and graph finish at the same time
      duration: DURATION - 319,
      delay: function(el, i) { return i + 200 },
    })
  }
})
