export default (text, textWidth) => ({
  text: text,
  textWidth: textWidth,
  width: 0,
  observer: null,

  get requiredTextCount () {
    return Math.ceil(2 * this.width / this.textWidth)
  },

  init() {
    this.width = this.$refs.target.getBoundingClientRect().width
    this.listen()
    this.updateText()

    this.$watch('width', () => {
      this.updateText()
    })
  },

  updateText() {
    const fulltext = []

    while (fulltext.length < this.requiredTextCount) {
      fulltext.push(`<span class="marquee-fragment relative -top-2">${text}</span>`)
    }

    this.$refs.target.innerHTML = fulltext.join(' ')
  },

  listen() {
    this.observer = new ResizeObserver(this.onResize.bind(this))
    this.observer.observe(document.body)
  },

  onResize(entries) {
    this.width = entries[0].contentRect.width
  }
})
