import anime from 'animejs'
import { delay } from 'lodash-es'

export default () => ({
  animate(delayInMS) {
    anime.set(this.$el, {
      opacity: 0,
    })

    delay(() => {
      anime({
        targets: this.$el,
        opacity: 1,
        duration: 500,
        easing: 'linear',
      })

      anime({
        targets: this.$refs.arrows,
        rotateZ: [90, 359],
        opacity: [0, 1],
        easing: 'easeOutSine',
        duration: 1500,
      })

      anime.timeline()
        .add({
          targets: this.$refs.magentaArrow,
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeOutSine',
          duration: 1500,
        })
        .add({
          targets: this.$refs.magentaArrowTip,
          opacity: [0,1],
          easing: 'easeOutSine',
          duration: 300,
        }, 1200)

      anime.timeline()
        .add({
          targets: this.$refs.redArrow,
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeOutSine',
          duration: 1500,
        })
        .add({
          targets: this.$refs.redArrowTip,
          opacity: [0,1],
          easing: 'easeOutSine',
          duration: 300,
        }, 1200)
    }, delayInMS)
  },
})
