import debounce from 'lodash/debounce'

export default () => ({
  showNav: false,
  scroll: { x: 0, y: 0 },
  introAnimationDone: false,
  showHeaderBar: false,
  activeSection: '',

  init() {
    this.updateScrollObject()

    if (window.scrollY > 0) {
      this.introAnimationDone = true
      window.addEventListener('load', () => {
        this.$dispatch('start-hero-slider')
      })

    } else {
      window.addEventListener('load', () => {
        this.$dispatch('run-intro-animation')
      })
    }

    window.addEventListener('intro-animation-end', () => {
      this.introAnimationDone = true
    })

    window.addEventListener('toggle-menu', () => {
      this.showNav = !this.showNav
    })

    window.addEventListener('scroll', debounce(() => {
      this.showHeaderBar = window.scrollY > (window.innerHeight * 0.4)
    }))

    window.addEventListener('scroll', debounce(this.updateScrollObject.bind(this)))
  },

  updateScrollObject() {
    this.scroll.x = window.scrollX
    this.scroll.y = window.scrollY
  }
})
