import anime from 'animejs/lib/anime.es.js'

export default (duration) => ({
  duration: duration ?? 500,
  fragments: null,
  aosId: null,
  listeners: {},

  init() {
    this.aosId = this.$el.dataset.aosId ?? null

    this.fragments = this.$refs.textFragments.querySelectorAll('span')
    Array.from(this.fragments).forEach(fragment => {
      fragment.style.opacity = 0
    })

    this.listeners[`aos:in:${this.aosId}`] = this.animate.bind(this)

    if (this.aosId) {
      Object.keys(this.listeners).forEach(listener => {
        document.addEventListener(listener, this.listeners[listener].bind(this))
      })
    }
  },

  animate() {
    anime({
      targets: this.fragments,
      keyframes: [
        { opacity: 0, duration: 0, },
        { scaleX: 1.15, scaleY: 1.15, opacity: 1, duration: this.duration * 0.5 },
        { scaleX: 1.0, scaleY: 1.0, duration: this.duration * 0.5 },
      ],
      easing: 'easeInOutQuad',
      duration: this.duration,
      delay: anime.stagger(200),
    })
  }
})
