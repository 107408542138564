import anime from 'animejs/lib/anime.es.js'

const settings = {
  duration: 500,
  easing: 'easeInOutSine',
}

export default () => ({
  openingAnimation() {
    anime({
      targets: this.$refs.bar1,
      translateX: [1.5, 1.5],
      translateY: [15, 15],
      rotateZ: '45deg',
      ...settings,
    })
    anime({
      targets: this.$refs.bar2,
      translateX: [1.5, 1.5],
      translateY: [15, 15],
      rotateZ: '-45deg',
      ...settings,
    })
  },

  closingAnimation() {
    anime({
      targets: this.$refs.bar1,
      translateX: [1.5, 1.5],
      translateY: [15, 15],
      rotateZ: 0,
      ...settings,
    })
    anime({
      targets: this.$refs.bar2,
      translateX: [1.5, 1.5],
      translateY: [15, 15],
      rotateZ: 0,
      ...settings,
    })
  }
})
