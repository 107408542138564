import anime from 'animejs'
import { delay } from 'lodash-es'

export default () => ({
  animate(delayInMS) {
    anime.set(this.$el, {
      opacity: 0,
    })

    delay(() => {
      anime({
        targets: this.$el,
        opacity: 1,
        duration: 500,
        easing: 'linear',
      })

      anime({
        targets: [this.$el],
        translateY: [
          { value: 0 },
          { value: 6 },
          { value: 2 },
          { value: 6 },
          { value: 0 },
        ],
        duration: 1000,
        easing: 'easeInOutQuad',
      })
    }, delayInMS)
  },
})
