// Components
import ClearMind from './components/ClearMind.js'
import ReturnOnInvestment from './components/ReturnOnInvestment.js'
import CountUp from './components/CountUp.js'
import Handshake from './components/Handshake.js'
import Header from './components/Header.js'
import HeroSlider from './components/HeroSlider.js'
import GrowthGraph from './components/GrowthGraph.js'
import Marquee from './components/Marquee.js'
import Menu from './components/Menu.js'
import MenuToggle from './components/MenuToggle.js'
import CloseMenuButton from './components/CloseMenuButton.js'
import OneOutOfFour from './components/OneOutOfFour.js'

// Magics
import ScrollTo from './magics/scrollTo'

// Pages
import Home from './pages/Home.js'
import Default from './pages/Default.js'

import Alpine from 'alpinejs'
import Intersect from '@alpinejs/intersect'
import 'lazysizes'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { debounce } from 'lodash-es'

// Magics
Alpine.magic('scrollTo', ScrollTo)

// Plugins
Alpine.plugin(Intersect)

// Components
Alpine.data('ClearMind', ClearMind)
Alpine.data('ReturnOnInvestment', ReturnOnInvestment)
Alpine.data('CountUp', CountUp)
Alpine.data('Handshake', Handshake)
Alpine.data('Header', Header)
Alpine.data('HeroSlider', HeroSlider)
Alpine.data('GrowthGraph', GrowthGraph)
Alpine.data('Marquee', Marquee)
Alpine.data('Menu', Menu)
Alpine.data('MenuToggle', MenuToggle)
Alpine.data('CloseMenuButton', CloseMenuButton)
Alpine.data('OneOutOfFour', OneOutOfFour)

// Pages
Alpine.data('Home', Home)
Alpine.data('Default', Default)

Alpine.start()
window.Alpine = Alpine

const screen = {
  width: window.innerWidth,
  height: window.innerHeight,
}

Alpine.magic('screen', () => {
  return screen
})

window.addEventListener('resize', debounce(() => {
  screen.width = window.innerWidth
  screen.height = window.innerHeight
  AOS.refresh()
}))

AOS.init({
  offset: 200,
})
