import { debounce } from 'lodash-es'

export default () => ({
  showNav: false,
  scroll: { x: 0, y: 0 },

  init() {
    this.updateScrollObject()

    window.addEventListener('toggle-menu', () => {
      this.showNav = !this.showNav
    })

    window.addEventListener('scroll', debounce(this.updateScrollObject.bind(this)))
  },

  updateScrollObject() {
    this.scroll.x = window.scrollX
    this.scroll.y = window.scrollY
  }
})
