import anime from "animejs";

let raf = null

export default (duration) => ({
  images: [],
  progress: null,
  currentIndex: 0,
  animationStarted: 0,
  showHeart: true,
  duration,

  init() {
    this.images.push(...this.$refs.imageContainer.children)
    this.progress = Array(this.images.length).fill(0)
    this.progress[0] = 0.001 // Must be slightly larger than 0 to show first image immediately
  },

  startImageLoop() {
    window.cancelAnimationFrame(raf)
    raf = window.requestAnimationFrame(this.run.bind(this))
  },

  run(time) {
    if (!this.animationStarted) {
      this.animationStarted = time
    }

    this.progress[this.currentIndex] = parseInt((time - this.animationStarted).toString()) / (this.duration)

    if (this.progress[this.currentIndex] > 1) {
      this.progress[this.currentIndex] = 0
      this.animationStarted = null

      let nextSlide = this.currentIndex + 1
      if (nextSlide > (this.images.length - 1)) {
        nextSlide = 0
      }

      this.currentIndex = nextSlide
    }

    raf = window.requestAnimationFrame(this.run.bind(this))
  },

  async runIntroAnimation() {
    this.showHeart = true
    const animation = anime({
      targets: '#heart .stripe',
      translateY: [
        { value: 0, duration: 150 },
        { value: -1.5, duration: 250, easing: 'linear' },
        { value: 0.75, duration: 150, easing: 'linear' },
        { value: -1.5, duration: 250, easing: 'linear' },
        { value: 0, duration: 150, easing: 'linear' },
        { value: 0, duration: 200 }
      ],
      loop: 2,
      delay: (el, i) => i * 35,
    })

    return animation.finished
      .then(() => {
        this.showHeart = false
        this.startImageLoop()
        this.$dispatch('intro-animation-end')
      })
  }
})
