import anime from 'animejs/lib/anime.es.js'

export default (targetValue, duration) => ({
  currentValue: 0,
  targetValue,
  duration,
  observer: null,
  listeners: {},

  init() {
    this.aosId = this.$el.dataset.aosId ?? null
    this.$refs.target.innerHTML = this.currentValue

    this.formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 1,
    })

    this.listeners[`aos:in:${this.aosId}`] = this.animate.bind(this)

    if (this.aosId) {
      Object.keys(this.listeners).forEach(listener => {
        document.addEventListener(listener, this.listeners[listener].bind(this))
      })
    }
  },

  animate() {
    this.currentValue = 0

    anime({
      targets: this,
      currentValue: this.targetValue,
      easing: 'linear',
      duration: this.duration,
      update: () => {
        this.$refs.target.innerHTML = Number.isSafeInteger(this.targetValue) ? parseInt(this.currentValue) : this.formatter.format(this.currentValue)
      }
    })
  },
})
