import debounce from 'lodash/debounce'

export default () => ({
  lastScrollY: 0,
  scrollDelta: 0,
  compact: false,

  init() {
    window.addEventListener('scroll', debounce(this.onScroll.bind(this)))

    this.$watch('scrollDelta', () => {
      this.compact = this.scrollDelta > 0
    })
  },

  onScroll(e) {
    this.scrollDelta = window.scrollY - this.lastScrollY
    this.lastScrollY = window.scrollY
  }
})
