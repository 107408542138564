import anime from 'animejs'
import { delay } from 'lodash-es'

export default () => ({
  animate(delayInMS) {
    const COLORS = {
      BLACK: '#282d41',
      RED: '#ff0035',
      MAGENTA: '#ff0067',
    }

    anime.set(this.$el, {
      opacity: 0,
    })

    anime.set(this.$el.querySelectorAll('path'), {
      stroke: COLORS.BLACK,
    })

    delay(() => {
      anime.timeline()
        .add({
          targets: this.$el,
          opacity: 1,
          duration: 500,
          easing: 'linear',
        }, 0)
        .add({
          targets: [ this.$refs.magentaDot3, this.$refs.magentaDot2, this.$refs.magentaDot1 ],
          stroke: [COLORS.BLACK, COLORS.MAGENTA],
          duration: 500,
          easing: 'linear',
          delay: anime.stagger(300)
        }, 300)
        .add({
          targets: this.$refs.connection1,
          stroke: [COLORS.BLACK, COLORS.MAGENTA],
          duration: 500,
          easing: 'linear',
        }, 450)
        .add({
          targets: this.$refs.connection2,
          stroke: [COLORS.BLACK, COLORS.MAGENTA],
          duration: 500,
          easing: 'linear',
        }, 750)
        .add({
          targets: [ this.$refs.wifiBar1, this.$refs.wifiBar2, this.$refs.wifiBar3 ],
          opacity: [0, 1],
          duration: 1000,
          easing: 'linear',
          delay: anime.stagger(300),
        }, 900)
    }, delayInMS)
  }
})
