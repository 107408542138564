import anime from 'animejs/lib/anime.es.js'

export default () => ({

  openingAnimation() {
    anime({
      targets: this.$refs.bar1,
      translateX: [0, 4],
      translateY: [5.5, 5.5],
      rotateZ: '45deg',
      fill: '#fff',
      duration: 500,
      easing: 'easeInOutSine'
    })
    anime({
      targets: this.$refs.bar2,
      translateX: [0, -4],
      translateY: [14.5, 3.5],
      rotateZ: '-45deg',
      fill: '#fff',
      duration: 500,
      easing: 'easeInOutSine'
    })
    anime({
      targets: this.$refs.bar3,
      opacity: 0,
      duration: 500,
      easing: 'easeInOutSine'
    })
  },

  closingAnimation() {
    anime({
      targets: this.$refs.bar1,
      translateX: [4, 0],
      translateY: [5.5, 5.5],
      rotateZ: 0,
      fill: '#000',
      duration: 500,
      easing: 'easeInOutSine'
    })
    anime({
      targets: this.$refs.bar2,
      translateX: [-4, 0],
      translateY: [3.5, 14.5],
      rotateZ: 0,
      fill: '#000',
      duration: 500,
      easing: 'easeInOutSine'
    })
    anime({
      targets: this.$refs.bar3,
      opacity: 1,
      duration: 500,
      easing: 'easeInOutSine'
    })
  }
})
